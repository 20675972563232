import { useEffect } from 'react';
// material
import { Box, Grid, Container, Typography, Stack } from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
import { AppOperadores, AppViewLoginLogsRow, AppWalletsRow } from '../components/_dashboard/app';
import useUsers from '../features/stores/user';

import useAppSettings from '../features/stores/settings';
// ----------------------------------------------------------------------

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#00AB55' : '#00AB55'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}));

export default function Settings() {
  const { enableMultiOperador, op, fetchConfig, fetchSettingsWalletUser } = useAppSettings();
  const { userData } = useUsers();
  const handleEnableMultiOperador = async (id, enabled) => {
    enableMultiOperador(id, enabled);
  };

  useEffect(() => {
    fetchConfig();
    fetchSettingsWalletUser();
  }, [fetchConfig]);

  return (
    <Page title="Configurações | Team TradingView">
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 5 }}>
          <Typography variant="h4">Configurações</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Multioperador</Typography>
            <Typography>Off</Typography>

            <AntSwitch
              checked={op.multiUser}
              onChange={() => handleEnableMultiOperador(1, !op.multiUser)}
              inputProps={{ 'aria-label': 'ant design' }}
            />

            <Typography>On</Typography>
          </Stack>
        </Box>

        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWalletsRow />
            <AppSettings title="CADASTRO DE CARTEIRAS" subheader="Suas wallets" />
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            {op && op.multiUser ? <AppOperadores /> : null}
          </Grid>

          {/* {userData && userData[0].role === 'ADMIN' ? (
            <Grid item xs={12} md={6} lg={8}>
              <AppViewLoginLogs />
              <AppViewLoginLogsRow />
            </Grid>
          ) : null} */}

          <Grid item xs={12} md={6} lg={8}>
            {/* <AppNewsUpdate /> */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
