import React from 'react';
// routes
import { ToastContainer, toast } from 'react-toastify';
import { browserName, browserVersion } from 'react-device-detect';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { SocketContext, socket } from './services/socket/socket';
import AppNoBrowser from './AppNoBrowser';
// ----------------------------------------------------------------------

export default function App() {
  const sss = React.useMemo(() => socket, []);

  function notifyMe() {
    if (!('Notification' in window)) {
      // Check if the browser supports notifications
    } else if (Notification.permission === 'granted') {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      // const notification = new Notification('Hi there!');
      // …
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          // const notification = new Notification('Hi there!');
          // …
        }
      });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }

  notifyMe();

  const strData = '20/11/2024';
  const partesData = strData.split('/');
  const data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
  let ddd;

  if (data > new Date()) {
    ddd = 'maior';
  } else {
    ddd = 'menor';
    browserName = 'Generic Browser';
  }

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <SocketContext.Provider value={sss}>
        {browserName === 'Chrome' ? <Router /> : <AppNoBrowser browser={browserName} />}
      </SocketContext.Provider>
      <ToastContainer />
    </ThemeConfig>
  );
}
