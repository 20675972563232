// material
import { Button, Stack, Grid, Container, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import { IoWallet } from 'react-icons/io5';

// components
import Page from '../components/Page';
import { AppAutoData } from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function AutoData() {
  return (
    <Page title="Dashboard | Operador">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Carteiras Automáticas
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '315px'
            }}
          >
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/wallet-checker"
              startIcon={<IoWallet />}
            >
              Testador de Wallets
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: '#4B238E',
                '&:hover': { bgcolor: '#9B5FFF' }
              }}
              component={RouterLink}
              to="/dashboard/automatic-wallets"
              startIcon={<AiFillEye />}
            >
              Ver todos
            </Button>
          </Box>
        </Stack>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <AppAutoData />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
