import { AiFillChrome, AiFillApple, AiFillAndroid } from 'react-icons/ai';

import { SiLinux } from 'react-icons/si';
import { GrWindows } from 'react-icons/gr';
import { FaFirefox, FaSafari, FaOpera } from 'react-icons/fa';
import { IoLogoAppleAppstore } from 'react-icons/io5';
// ----------------------------------------------------------------------

export const CreateOsIcon = (os) => {
  switch (os) {
    case 'Android':
      return <AiFillAndroid size={15} />;
    case 'Windows':
      return <GrWindows size={15} />;
    case 'Mac OS':
      return <IoLogoAppleAppstore size={15} />;
    case 'Linux':
      return <SiLinux size={15} />;
    case 'ios':
      return <AiFillApple size={15} />;
    default:
      return <AiFillApple size={15} />;
  }
};

export const CreateBrowserIcon = (browser) => {
  switch (browser) {
    case 'Chrome':
      return <AiFillChrome size={15} />;
    case 'Firefox':
      return <FaFirefox size={15} />;
    case 'Opera':
      return <FaOpera size={15} />;
    case 'Safari':
      return <FaSafari size={15} />;
    default:
      return <FaSafari size={15} />;
  }
};
