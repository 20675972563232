import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button
} from '@mui/material';
import AddUserDialog from '../../../layouts/dashboard/AddUserDialog';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function UserListToolbar({ numSelected, filterName, onFilterName }) {
  const [openAddUser, setOpenAddUser] = useState(false);

  const handleCloseUser = () => {
    setOpenAddUser(false);
  };

  const handleClickOpenUser = () => {
    setOpenAddUser(true);
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      <AddUserDialog isOpenAddUser={openAddUser} handleCloseUser={handleCloseUser} />
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected}
          {numSelected > 1 ? ' selecionados' : ' selecionado'}
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Pesquisar usuários..."
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          <Button
            sx={{
              width: 150,
              height: 40
            }}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleClickOpenUser}
          >
            Novo usuário
          </Button>
        </Box>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Deletar">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : null}
    </RootStyle>
  );
}
