import { useFormik } from 'formik';
import { useState } from 'react';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { ProductList, ProductFilterSidebar } from '../components/_dashboard/products';
//
import PRODUCTS from '../_mocks_/products';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  return (
    <Page title="Ferramentas | Team TradingView">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Ferramentas
        </Typography>

        <ProductList products={PRODUCTS} />
      </Container>
    </Page>
  );
}
