import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

let store = (set, get) => ({
  bots: [],
  attack: [],
  fetchByUser: async (user) => {
    const response = await api.get(`/api/bot/user/${user}`, {
      headers: authHeader()
    });
    set({ bots: response.data });
  },

  addBot: async (responsavel, criador, url, isDefault) => {
    const response = await api.post(
      '/api/bot',
      { responsavel, criador, url, default: isDefault },
      { headers: authHeader() }
    );

    await set({ bots: [...new Set([...get().bots, response.data])] });
    window.location.reload();
  },

  deleteBot: async (id) => {
    const response = await api.delete(`/api/bot/${id}`, { headers: authHeader() });

    set({
      bots: get().bots.filter((bk1) => bk1.id !== response.data.id)
    });
    return 1;
  },

  // ATTACK
  addAttack: async (url, server, status, nick) => {
    const response = await api.post(
      '/api/bot/attack/create',
      { url, server, status, responsavel: nick },
      { headers: authHeader() }
    );

    console.log(response.data);
    if (response.data.err) {
      alert(response.data.msg);
      return;
    }

    set({ attack: [...new Set([...get().attack, response.data])] });
  },

  setAttack: async (id, status) => {
    const response = await api.put(
      `/api/bot/attack/set/${id}`,
      { status },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      attack: state.attack.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  },

  fetchAttackByUser: async (user) => {
    const response = await api.get(`/api/bot/attack/user/${user}`, {
      headers: authHeader()
    });
    console.log(response.data);
    set({ attack: response.data });
  }
});

store = devtools(store);
store = persist(store, { name: 'useUserSettings' });

const useStore = create(store);

export default useStore;
