import { useEffect, useState } from 'react';
import { Stack, Button, Tooltip, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// ----------------------------------------------------------------------
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useSettingsStore from '../features/stores/settings';
import ButtonLink from './Link';

import useAdsClickStore from '../features/stores/ads-clicks';
import DeleteOperablesDialog from './DeleteOperablesDialog';

export default function Views() {
  const [open, setOpen] = useState(false);
  const { adsClicks } = useAdsClickStore();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Stack direction="row" spacing={1} sx={{ display: 'flex', justifyContent: 'center', pb: 1 }}>
      <DeleteOperablesDialog isOpen={open} handleClose={handleClose} />
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          width: '35%'
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#28A161',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: 20,
            width: 100,
            fontSize: 13
          }}
        >
          {adsClicks.length}&nbsp;
          {adsClicks.length > 1 ? 'Acessos' : 'Acesso'}
        </Typography>
        &nbsp;
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#BC3B0E',
            color: 'white',
            fontWeight: 'bold',
            borderRadius: 20,
            width: 130,
            fontSize: 13
          }}
        >
          {adsClicks.filter((e) => e.status === true).length}&nbsp;
          {adsClicks.filter((e) => e.status === true).length > 1 ? 'Bloqueados' : 'Bloqueado'}
        </Typography>
        &nbsp;
        <Button
          variant="outlined"
          onClick={() => handleClickOpen()}
          sx={{
            fontSize: 9,
            height: 23,
            width: 130,
            borderColor: 'red',
            color: 'red',
            '&:hover': { bgcolor: 'red', color: 'white', borderColor: 'red' }
          }}
        >
          Excluir Infos
        </Button>
        &nbsp;
        <Button
          variant="outlined"
          component={RouterLink}
          to="/dashboard/adsclicks"
          sx={{
            fontSize: 12,
            height: 23,
            width: 130,
            borderColor: '#0B831F',
            color: '#0B831F',
            '&:hover': { bgcolor: '#48B61F', color: 'white' }
          }}
        >
          Ads Clicks
        </Button>
        &nbsp;
        <ButtonLink />
      </Box>
    </Stack>
  );
}
