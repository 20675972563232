import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

let store = (set, get) => ({
  userData: [],
  users: [],
  fetchUsers: async () => {
    const response = await api.get('/api/users', {
      headers: authHeader()
    });
    set({ users: response.data });
  },
  setUserAsOperador: async (id, isOperador) => {
    const response = await api.put(
      `/api/users/setoperador`,
      { id, isOperador },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      users: state.users.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            isOperador: response.data.isOperador
          };
        }
        return item;
      })
    }));
  },

  fetchUserData: async (id) => {
    const response = await api.get(`/api/users/${id}`, { headers: authHeader() });
    console.log(response.data);
    set({ userData: response.data });
  },

  deleteUser: async (id) => {
    await api.delete(`/api/users/${id}`, { headers: authHeader() });
    set({
      users: get().users.filter((user) => user.id !== id)
    });
  },

  editUser: async (id, nick, pass) => {
    const response = await api.put(
      `/api/users/edit/${id}`,
      { nick, password: pass },
      { headers: authHeader() }
    );

    set({ users: [...new Set([...get().users, response.data])] });
  },

  addNewUser: async (nick, password) => {
    const response = await api.post(
      '/api/auth/local/signup',
      { nick, password },
      { headers: authHeader() }
    );
  },

  saveCopy: async (data) => {
    await api.post('/api/users/savedata', { data }, { headers: authHeader() });
  },

  addUserData: (user) => {
    set({ userData: [...new Set([...get().userData, user])] });
  },

  banUser: async (id, status) => {
    const response = await api.put(`/api/auth/ban/${id}`, { status }, { headers: authHeader() });
    set((state) => ({
      users: state.users.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            isBan: response.data.isBan
          };
        }
        return item;
      })
    }));
  },

  updateUserStatus: async (id, status) => {
    const response = await api.put(
      `/api/users/status/${id}`,
      { status },
      { headers: authHeader() }
    );
    set((state) => ({
      users: state.users.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  },

  updateUser: (payload) => {
    set((state) => ({
      users: state.users.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            status: payload.status
          };
        }
        return item;
      })
    }));
  }
});

store = devtools(store);
store = persist(store, { name: 'userStore' });

// const useStore = create(devtools(store));
const useStore = create(store);

export default useStore;
