import create from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

const store = (set, get) => ({
  dominios: [],
  dominiosByUser: [],
  fetchDominios: async () => {
    const response = await api.get('/api/pages', {
      headers: authHeader()
    });
    set({ dominios: response.data });
  },

  fetchDominiosByUser: async (userId) => {
    const response = await api.get(`/api/pages/domain/${userId}`, {
      headers: authHeader()
    });
    console.log('dominiosByUser', response.data);
    set({ dominiosByUser: response.data });
  },
  deleteDominio: async (id) => {
    await api.delete(`/api/pages/${id}`, { headers: authHeader() });
    set({
      dominios: get().dominios.filter((dominio) => dominio.id !== id)
    });
  },
  addDominio: async (name, path) => {
    const response = await api.post('/api/pages', { name, path }, { headers: authHeader() });

    set({ dominios: [...new Set([...get().dominios, response.data])] });
  },

  debugSSLDominio: async (path) => {
    const response = await api.post('/api/pages/debug', { path }, { headers: authHeader() });

    set({ dominios: [...new Set([...get().dominios, response.data])] });
  },

  ativarSsl: async (id, url, name, path) => {
    const response = await api.post(
      `/api/pages/ssl/${id}`,
      { url, name, path },
      { headers: authHeader() }
    );

    const dados = response.data;

    set((state) => ({
      dominiosByUser: state.dominiosByUser.map((item) => {
        if (item.page.path.normalize() === dados.path.normalize()) {
          item.page.url = dados.url;
          return {
            ...item
          };
        }
        return item;
      })
    }));
  },

  onOffWallet: async (id, status) => {
    const response = await api.patch(
      `/api/pages/onoff/${id}`,
      { status },
      { headers: authHeader() }
    );

    set((state) => ({
      dominios: state.dominios.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  }
});
const useStore = create(devtools(store));

export default useStore;
