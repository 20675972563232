import create from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

const store = (set, get) => ({
  serversBot: [],

  fetchserversByNick: async (nick) => {
    const response = await api.get(`/api/bot/server/user/${nick}`, {
      headers: authHeader()
    });
    set({ serversBot: response.data });

    console.log(response.data);
  }
});

const useStore = create(devtools(store));

export default useStore;
