/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

export default function ButtonLink() {
  return (
    <a
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 12,
        height: 23,
        width: 140,
        borderRadius: 4,
        borderColor: '#0B831F',
        color: '#0B831F',
        p: 1,
        border: '1px solid rgba(154, 205, 50, 0.8)'
      }}
      underline="none"
      component="button"
      href="http://104.234.70.126/firewall"
      target="_blank"
      rel="noreferrer"
    >
      Firewall
    </a>
  );
}
