import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';

import { FaEthereum } from 'react-icons/fa';
import { BiWorld } from 'react-icons/bi';
import { IoSettingsSharp } from 'react-icons/io5';

import { AiFillBank, AiOutlineSwap } from 'react-icons/ai';
import { RiCoinsFill } from 'react-icons/ri';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfigAdmin = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Exchanges',
    path: '/dashboard/operador',
    icon: <FaEthereum width={22} height={22} size={22} />
  },
  {
    title: 'Dex',
    path: '/dashboard/auto-data',
    icon: <RiCoinsFill width={22} height={22} size={22} />
  },
  {
    title: 'Swap',
    path: '/dashboard/swap',
    icon: <AiOutlineSwap width={22} height={22} />
  },
  {
    title: 'Banking',
    path: '/dashboard/banking',
    icon: <AiFillBank width={22} height={22} />
  },
  {
    title: 'Domínios',
    path: '/dashboard/dominios',
    icon: <BiWorld width={22} height={22} />
  },
  {
    title: 'Configurações',
    path: '/dashboard/settings',
    icon: <IoSettingsSharp width={22} height={22} />
  }
];

export default sidebarConfigAdmin;
