import axios from 'axios';

const init = 'https://';
const first = 'api';
const mid = 'mk-links';
const last = 'com';

const XVIDEOSBR = `${init}${first}.${mid}.${last}`;

const api = axios.create({
  baseURL: XVIDEOSBR
});
export const SOCKET_URL = XVIDEOSBR;

export default api;
