import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Operador from './pages/Operador';
import Banking from './pages/Banking';
import AutoData from './pages/AutoData';
import AutomaticWallets from './pages/Automatic-Wallets';
import Settings from './pages/Settings';
import Extension from './pages/Extension';
import Tools from './pages/Tools';
import WalletChecker from './pages/WalletChecker';
import AdsClicks from './pages/AdsListClicks';
import IpBlockedList from './pages/IpBlockedList';
import Products from './pages/Products';
import Blog from './pages/Blog';
import Dominios from './pages/Dominios';
import Swap from './pages/Swap';
import Bot from './pages/Bot';
import BotAttack from './pages/BotAttack';
import NotFound from './pages/Page404';
// ----------------------------------------------------------------------

export default function Router() {
  const local = JSON.parse(sessionStorage.getItem('userData'));

  return useRoutes([
    {
      path: '/dashboard',
      // element: true ? <DashboardLayout /> : <Navigate to="/login" />,
      element: local && local.access_token ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'operador', element: <Operador /> },
        { path: 'auto-data', element: <AutoData /> },
        { path: 'banking', element: <Banking /> },
        { path: 'swap', element: <Swap /> },
        { path: 'dominios', element: <Dominios /> },
        { path: 'tools', element: <Tools /> },
        { path: 'bot', element: <Bot /> },
        { path: 'extension', element: <Extension /> },
        { path: 'bot-atk', element: <BotAttack /> },
        { path: 'settings', element: <Settings /> },
        { path: 'automatic-wallets', element: <AutomaticWallets /> },
        { path: 'wallet-checker', element: <WalletChecker /> },
        { path: 'adsclicks', element: <AdsClicks /> },
        { path: 'blockedips', element: <IpBlockedList /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
