import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Box } from '@mui/system';
import { SiGoogleads } from 'react-icons/si';
import * as url from 'url';

import useBotStore from '../../../../features/stores/bot';
import useUserStore from '../../../../features/stores/user';

export default function AddAdsDialog({ isOpen, handleClose }) {
  const [responsavel, setResponsavel] = React.useState();
  const [criador, setCriador] = React.useState();
  const [urls, setURLS] = React.useState('');

  const { addBot } = useBotStore();
  const { userData } = useUserStore();

  function extractDomain(link) {
    if (!link.startsWith('http')) {
      link = `https://${link}`;
    }
    const parsedUrl = url.parse(link);
    const replaced = parsedUrl.hostname.replace('www.', '');
    return replaced;
  }

  const handleAddNewWallet = async () => {
    const domain = extractDomain(urls.toString());

    if (responsavel && criador && urls) {
      await addBot(responsavel, criador, domain, 0);
      setCriador('');
      setURLS('');
      handleClose();
    } else {
      alert('Preencha todos os campos');
    }
  };

  React.useEffect(() => {
    setResponsavel(userData[0].nick);
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Proteger Campanha &nbsp; <SiGoogleads />{' '}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                  sx={{
                    width: '35%',
                    mt: 1
                  }}
                  disabled
                  id="outlined-basic"
                  label="Responsável"
                  variant="outlined"
                  onChange={(e) => setResponsavel(e.target.value)}
                  value={responsavel}
                />{' '}
                <TextField
                  sx={{
                    width: '60%',
                    mt: 1
                  }}
                  id="outlined-basic"
                  label="Criador"
                  variant="outlined"
                  onChange={(e) => setCriador(e.target.value)}
                  value={criador}
                />{' '}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TextField
                  sx={{
                    width: 500,
                    mt: 1
                  }}
                  id="outlined-basic"
                  label="URL"
                  variant="outlined"
                  onChange={(e) => setURLS(e.target.value)}
                  value={urls}
                />{' '}
              </Box>
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={handleAddNewWallet} autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
