import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import { AiOutlineCloudDownload } from 'react-icons/ai';
import { BsEraser } from 'react-icons/bs';

import { useEffect } from 'react';
import useExtensionStore from '../../../../features/stores/extension';

export default function ExtensionLogsDialog(props) {
  const { fetchLogs, logs, generateTxt, deleteLogs } = useExtensionStore();

  const [id, setID] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  const handleDownloadLogs = () => {
    generateTxt(props.name, 'other');
  };
  const handleClearLogs = () => {
    deleteLogs(props.id);
  };

  useEffect(() => {
    const fetchLogsAsync = () => {
      setTimeout(() => {
        fetchLogs();
        setLoading(false);
      }, 2000);
    };
    fetchLogsAsync();
  }, []);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Logs das extensões ➡️ </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box
            sx={{
              height: 500,
              width: 600
            }}
          >
            {loading ? (
              <div>loading...</div>
            ) : (
              logs.map((log, index) => (
                <Typography
                  sx={{
                    paddingRight: 3,
                    fontSize: 12
                  }}
                  key={index}
                >
                  {log.text ? log.text.trim() : <br />}
                </Typography>
              ))
            )}
          </Box>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="error" onClick={props.handleClose}>
          Cancelar
        </Button>
        <Button variant="outlined" color="error" onClick={handleClearLogs}>
          {' '}
          <BsEraser size={20} /> &nbsp; Limpar
        </Button>
        <Button variant="outlined" autoFocus onClick={handleDownloadLogs}>
          {' '}
          <AiOutlineCloudDownload size={22} /> &nbsp; Baixar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
