import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

import usePagesStore from '../../../features/stores/dominios';

export default function AddDominiolDialog({ isOpen, handleClose }) {
  const [name, setName] = React.useState();
  const [path, setPath] = React.useState();

  const { addDominio } = usePagesStore();

  const handleAddNewWallet = async () => {
    if (name && path) {
      await addDominio(name, path);
      setPath('');
      setName('');
      handleClose();
    } else {
      alert('Preencha todos os campos');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Cadastrar Carteira 💰</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                sx={{
                  width: 150,
                  mt: 1
                }}
                id="outlined-basic"
                label="Nome"
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />{' '}
              <TextField
                sx={{
                  width: 400,
                  mt: 1
                }}
                id="outlined-basic"
                label="Path"
                variant="outlined"
                onChange={(e) => setPath(e.target.value)}
                value={path}
              />{' '}
            </Box>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={handleAddNewWallet} autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
