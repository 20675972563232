import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { FaExpeditedssl } from 'react-icons/fa';
import { SiPowershell } from 'react-icons/si';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  DialogTitle,
  Dialog,
  Typography,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import AtivarSslDialog from './AtivarSslDialog';
import DeleteSslDialog from './DeleteSslDialog';
import useUserData from '../../../features/stores/user';
import useDominiosData from '../../../features/stores/dominios';
// ----------------------------------------------------------------------

export default function UserMoreMenu({ id, domain, domainName, path }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showDialogFlushIps, setShowDialogFlushIps] = useState(false);
  const [isLoadingFlush, setLoadingFlush] = useState(false);
  const { userData } = useUserData();
  const { debugSSLDominio } = useDominiosData();

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDebugSSL = () => {
    setShowDialogFlushIps(true);
    setLoadingFlush(true);
    debugSSLDominio(path);
  };

  function MakeDialogFlushIPS() {
    return (
      <div>
        <Dialog
          open={showDialogFlushIps}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {isLoadingFlush ? `Fazendo download do arquivo de ${path}` : 'Sucesso!'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {isLoadingFlush ? (
                <CircularStatic />
              ) : (
                <Typography>Prossiga com a ativação do SSL!</Typography>
              )}
            </DialogContentText>
          </DialogContent>
          {!isLoadingFlush ? (
            <DialogActions>
              <Button onClick={() => setShowDialogFlushIps(false)} autoFocus>
                OK
              </Button>
            </DialogActions>
          ) : null}
        </Dialog>
      </div>
    );
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired
  };

  function CircularStatic() {
    const [progress, setProgress] = useState(1);

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? setLoadingFlush(false) : prevProgress + 5
        );
      }, 100);
      return () => {
        clearInterval(timer);
      };
    }, []);

    return <CircularProgressWithLabel value={progress} />;
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <MakeDialogFlushIPS />
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* {userData && userData[0].role === 'ADMIN' ? (
          <MenuItem onClick={handleClickOpenDelete} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Deletar SSL" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : null} */}

        {/* <MenuItem
          onClick={handleDebugSSL}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <SiPowershell width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Debug SSL" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

        <MenuItem
          onClick={handleClickOpen}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <FaExpeditedssl width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Ativar SSL" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <AtivarSslDialog
        id={id}
        domainName={domainName}
        path={path}
        isOpen={open}
        handleClose={handleClose}
      />
      <DeleteSslDialog
        id={id}
        isOpen={openDelete}
        handleCloseDelete={handleCloseDelete}
        domain={domain}
      />
    </>
  );
}
