import create from 'zustand';
import { devtools } from 'zustand/middleware';
import api from '../../services/api/api';
import authHeader from '../../services/auth/authHeader';

const store = (set, get) => ({
  extensions: [],
  unninstall: [],
  logs: [],
  fetchExtensions: async (nick) => {
    const response = await api.get(`/api/extension/dados/${nick}`, {
      headers: authHeader()
    });
    console.log('extensions', response.data);
    set({ extensions: response.data.allClients });
    set({ unninstall: response.data.uninstaledExtensions });
  },

  fetchLogs: async () => {
    const response = await api.get('/api/extension/logs', {
      headers: authHeader()
    });
    const data = response.data.split('\n').map((x) => ({ text: x }));
    set({ logs: data });
  }
});
const useStore = create(devtools(store));

export default useStore;
