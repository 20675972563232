import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { toast } from 'react-toastify';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import usePagesStore from '../../../features/stores/dominios';

export default function AtivarSslDialog({ id, domainName, isOpen, handleClose, path }) {
  const [url, setUrl] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [text, setText] = React.useState('Por favor aguarde.');
  const { ativarSsl } = usePagesStore();

  const handleEnableSSL = async (id) => {
    setShowDialog(true);
    await ativarSsl(id, url, domainName, path);
    setShow(false);
    setTimeout(() => {
      // setText('SSL ativado com sucesso!');
      notify('SSL ativado com sucesso!');
      setShowDialog(false);
      handleClose();
    }, 15000);
  };

  const notify = (args) => {
    toast(`✅ ${args}.`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  function MakeDialog() {
    return (
      <div>
        <Dialog
          open={showDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Ativando SSL 🔒</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>Ativação em andamento...</Typography>
              <Typography>{text}</Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      {show ? (
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Ativar SSL 🔒</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                sx={{
                  width: 500,
                  mt: 1
                }}
                id="outlined-basic"
                label="url"
                variant="outlined"
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />{' '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleClose}>
              Cancelar
            </Button>
            <Button onClick={() => handleEnableSSL(id)} autoFocus>
              {showDialog ? <CircularProgress size={18} /> : 'Ativar'}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <MakeDialog />
      )}
    </div>
  );
}
