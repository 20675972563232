import { useState, useEffect, useContext, useCallback } from 'react';
// material
import { Box, Stack, Typography, Grid } from '@mui/material';
import { AiOutlineFieldTime, AiOutlineUser } from 'react-icons/ai';
import ptLocale from 'date-fns/locale/pt-BR';
import { formatDistance } from 'date-fns';
import { toast } from 'react-toastify';
import { CreateUserStatusColor, CreateUserStatusTextColor } from '../../utils/ui/createColors';
import useUsers from '../../features/stores/user';

import { SocketContext } from '../../services/socket/socket';

// ----------------------------------------------------------------------

export default function UserStatus() {
  const socket = useContext(SocketContext);

  const { users, fetchUsers, updateUser, userData } = useUsers();

  const notify = (args) => {
    toast(`${args} está ON ✅`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const handleNewAccessStatus = useCallback(
    (data) => {
      updateUser(data);
      notify(data.nick);
    },
    [updateUser]
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    socket.on('newLogin', (data) => {
      handleNewAccessStatus(data);
    });

    socket.on('isOffUser', () => {
      fetchUsers();
    });

    return () => {
      socket.off('newLogin');
      socket.off('isOffUser');
    };
  }, [handleNewAccessStatus, socket]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: 80,
        borderRadius: 0.5
      }}
    >
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0.3
        }}
      >
        {users &&
          users
            .filter((u) => u.status !== 'Offline')
            .slice(-4)
            .sort((a, b) => (a.status < b.status ? -1 : -1))
            .map((ads) => (
              <Grid
                key={ads.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '40%',
                  justifyContent: 'space-between',
                  width: '50%',
                  borderRadius: 0.5,
                  textAlign: 'center'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    backgroundColor: `${CreateUserStatusColor(ads.status)}`,
                    borderRadius: 0.5,
                    marginLeft: 0.5
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      fontSize: 9,
                      fontWeight: 'bold',
                      color: `${CreateUserStatusTextColor(ads.status)}`,
                      height: '100%',
                      width: '100%',
                      alignItems: 'center',
                      p: 1
                    }}
                  >
                    <AiOutlineUser size={15} />
                    &nbsp;{ads.nick} - {ads.status}
                  </Typography>
                </Box>
              </Grid>
            ))}
      </Grid>
    </Box>
  );
}
